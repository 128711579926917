import React from "react"
import { graphql } from "gatsby"
import RichTextToReact from "rich-text-to-react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import ClinicInfo from "./components/ClinicInfo"
import UrgentClinicInfo from "./components/UrgentClinicInfo"
import Overview from "./components/Overview"
import PrepareForAppointment from "./components/PrepareForAppointment"
import ServicesCard from "../components/ServicesCard"
import PreFooter from "../components/PreFooter"
import Doctors from "./components/Doctors"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./locationStyles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const LocationTemplate = ({ data }) => {
  const locationData = data.contentfulLocation
  const title = locationData.locationTitle
  const resources = locationData.prefooterResources.map(
    ({ id, resourceTitle: title = "", resourceCopy: { raw = "" } }) => {
      return {
        id,
        title,
        body: (
          <RichTextToReact document={JSON.parse(raw)} options={renderOptions} />
        ),
      }
    }
  )
  return (
    <Layout>
      <Seo
        title={
          `${locationData.heroSection.eyebrow}` +
            " - " +
            `${locationData.heroSection.headline}` || title
        }
      />
      <div className={styles.locationWrapper}>
        <Hero
          eyebrow={`${locationData.heroSection.eyebrow}`}
          headline={`${locationData.heroSection.headline}`}
          image={`${locationData.heroSection.heroImage.fluid.srcWebp}`}
        />
        <Overview overviewData={locationData.overview} />
        {locationData.clinicInfo.isUrgentCareFacility ? (
          <UrgentClinicInfo clinicData={locationData.clinicInfo} />
        ) : (
          <ClinicInfo clinicData={locationData.clinicInfo} />
        )}
        <PrepareForAppointment pfaData={locationData.preparingForAppointment} />
        {locationData.clinicianPhotos ? (
          <Doctors drData={locationData.clinicianPhotos} />
        ) : null}
        <ServicesCard servicesData={locationData.servicesSectionCopy} />
        <PreFooter resources={resources} />
      </div>
    </Layout>
  )
}

export default LocationTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    contentfulLocation(id: { eq: $id }) {
      locationTitle
      heroSection {
        eyebrow
        headline
        heroImage {
          fluid {
            src
            srcWebp
          }
        }
      }
      overview {
        raw
      }
      clinicInfo {
        ... on ContentfulClinicInfo {
          isUrgentCareFacility
          address
          addressLine2
          locationMap {
            file {
              url
            }
          }
          locationName
          phoneNumber
          weekdayHours
          weekendHours
          directions {
            buttonText
            link
          }
        }
        ... on ContentfulUrgentCareClinicInfo {
          isUrgentCareFacility
          additionalInformation
          addressLine1
          addressLine2
          afterHoursUrgentCarePhoneNumber
          kpojServicesWeekdayHours
          locationMap {
            file {
              url
            }
          }
          locationName
          urgentCareWeekdayHours
          urgentCareWeekendHours
          weekdayPhoneNumber
          directions {
            buttonText
            link
          }
        }
      }
      preparingForAppointment {
        image {
          fluid {
            src
            srcWebp
          }
        }
        copy {
          raw
        }
        disclaimer
      }
      clinicianPhotos {
        copy {
          raw
        }
        clinicianPhotos {
          clinicianPage
          picture {
            fluid {
              srcSet
              srcWebp
            }
          }
          nameAndTitle
        }
      }
      prefooterResources {
        resourceCopy {
          raw
        }
        resourceTitle
      }
      servicesSectionCopy
    }
  }
`
