import React from "react"
import RichTextToReact from "rich-text-to-react"
import { INLINES } from "@contentful/rich-text-types"

import * as prepareForAppointmentStyles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const PrepareForAppointment = ({ pfaData }) => {
  return (
    <section className={prepareForAppointmentStyles.prepareForAppointment}>
      <div className={prepareForAppointmentStyles.content}>
        <img src={pfaData.image.fluid.srcWebp} alt="" />
        <div className={prepareForAppointmentStyles.copy}>
          <RichTextToReact
            document={JSON.parse(pfaData.copy.raw)}
            options={renderOptions}
          />
          <p>
            <small>{pfaData.disclaimer}</small>
          </p>
        </div>
      </div>
    </section>
  )
}

export default PrepareForAppointment
