import React from "react"
import Cta from "../../../components/Cta"
import * as clinicInfoStyles from "./styles.module.less"

const ClinicInfo = ({ clinicData }) => {
  return (
    <section className={clinicInfoStyles.clinicInfo}>
      <div className={clinicInfoStyles.content}>
        <img
          src={clinicData.locationMap.file.url}
          alt={clinicData.locationName}
        />
        <div className={clinicInfoStyles.clinicDetails}>
          <h2>{clinicData.locationName}</h2>
          <p>
            {clinicData.address} <br /> {clinicData.addressLine2}
          </p>
          <h3>Book a visit</h3>
          <p>
            <a href={"tel:" + clinicData.phoneNumber}>
              {clinicData.phoneNumber}
            </a>
          </p>
          <div className={clinicInfoStyles.hoursOfOperation}>
            <h3>Hours of operation</h3>
            <p>
              Weekdays: <strong>{clinicData.weekdayHours}</strong>
            </p>
            <p>
              Weekends: <strong>{clinicData.weekendHours}</strong>
            </p>
          </div>
          <div className={clinicInfoStyles.directionsCta}>
            <Cta
              text={`${clinicData.directions.buttonText}`}
              link={`${clinicData.directions.link}`}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClinicInfo
