import React from "react"
import Cta from "../Cta"
import ServicesBanner from "../ServicesBanner"
import * as servicesStyles from "./styles.module.less"

const ServicesCard = ({ servicesData }) => {
  return (
    <section className={servicesStyles.services}>
      <div className={servicesStyles.copy}>
        <h2>{servicesData}</h2>
      </div>
      <ServicesBanner />
      <Cta text="See all services" link="/services" />
    </section>
  )
}

export default ServicesCard
