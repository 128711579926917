import React from "react"
import RichTextToReact from "rich-text-to-react"
import EmployeePhoto from "../../../components/EmployeePhoto"
import { INLINES } from "@contentful/rich-text-types"

import * as doctorStyles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const Doctors = ({ drData }) => {
  return (
    <section className={doctorStyles.doctors}>
      <div className={doctorStyles.copy}>
        <RichTextToReact
          document={JSON.parse(drData.copy.raw)}
          options={renderOptions}
        />
      </div>
      <div className={doctorStyles.pictures}>
        {drData.clinicianPhotos &&
          drData.clinicianPhotos.map(
            ({ picture, nameAndTitle, clinicianPage }, index) => (
              <EmployeePhoto
                image={picture.fluid.srcWebp}
                title={nameAndTitle}
                link={clinicianPage}
                key={index}
              />
            )
          )}
      </div>
    </section>
  )
}

export default Doctors
