import React from "react"
import Cta from "../../../components/Cta"

import * as styles from "./styles.module.less"

const UrgentClinicInfo = ({ clinicData }) => {
  return (
    <section className={styles.clinicInfo}>
      <div className={styles.content}>
        <img
          src={clinicData.locationMap.file.url}
          alt={clinicData.locationName}
        />
        <div className={styles.clinicDetails}>
          <h2>{clinicData.locationName}</h2>
          <p>
            {clinicData.addressLine1} <br /> {clinicData.addressLine2}
          </p>
          <div className={styles.hoursWrapper}>
            <div className={styles.hoursCol}>
              <h4>Weekdays {clinicData.kpojServicesWeekdayHours}</h4>
              <p>
                <strong>
                  <a href={"tel:" + clinicData.weekdayPhoneNumber}>
                    {clinicData.weekdayPhoneNumber}
                  </a>
                </strong>
              </p>
            </div>
            <div className={styles.hoursCol}>
              <h4>After-hours urgent care</h4>
              <p>
                <strong>
                  <a href={"tel:" + clinicData.afterHoursUrgentCarePhoneNumber}>
                    {clinicData.afterHoursUrgentCarePhoneNumber}
                  </a>
                </strong>
              </p>
            </div>
          </div>
          <h4>Life-threatening emergency</h4>
          <p>
            <strong>911</strong>
          </p>
          <h4>KPOJ Services:</h4>
          <p>
            Weekdays: <strong>{clinicData.kpojServicesWeekdayHours}</strong>
          </p>
          <h4>Urgent care:</h4>
          <p>
            Weekdays: <strong>{clinicData.urgentCareWeekdayHours}</strong>
          </p>
          <p>
            Weekends and holidays:{" "}
            <strong>{clinicData.urgentCareWeekendHours}</strong>
          </p>
          <p className={styles.disclaimer}>
            <small>{clinicData.additionalInformation}</small>
          </p>
          <div className={styles.directionsCta}>
            <Cta
              text={`${clinicData.directions.buttonText}`}
              link={`${clinicData.directions.link}`}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default UrgentClinicInfo
