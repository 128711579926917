import React from "react"
import * as styles from "./styles.module.less"

const EmployeePhoto = ({ image, title, link }) => {
  return (
    <div className={styles.employeePhoto}>
      <div className={styles.photoContainer}>
        <img src={image} alt="" />
      </div>
      <a href={link}>
        <p>{title}</p>
      </a>
    </div>
  )
}

export default EmployeePhoto
