import React from "react"
import RichTextToReact from "rich-text-to-react"
import { INLINES } from "@contentful/rich-text-types"

import * as overViewStyles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, key, next) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a key={key} href={uri} {...attr}>
          {next(node.content, key, next)}
        </a>
      )
    },
  },
}

const Overview = ({ overviewData }) => {
  return (
    <section className={overViewStyles.overview}>
      <div className={overViewStyles.copy}>
        <RichTextToReact
          document={JSON.parse(overviewData.raw)}
          options={renderOptions}
        />
      </div>
    </section>
  )
}

export default Overview
